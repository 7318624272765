import React, { Fragment } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import FaPhoneAlt from '../../assets/faPhoneAlt.svg';
import FaGlobeAmericas from '../../assets/faGlobeAmericas.svg';
import FaEnvelopeOpen from '../../assets/faEnvelopeOpen.svg';
import FaClock from '../../assets/faClock.svg';
import FaComment from '../../assets/faComment.svg';
import FaFacebookF from '../../assets/faFacebookF.svg';
import FaInstagram from '../../assets/faInstagram.svg';
import FaTwitter from '../../assets/faTwitter.svg';
import FaLinkedinIn from '../../assets/faLinkedinIn.svg';
import FaYoutube from '../../assets/faYoutube.svg';
import Logo from '../../assets/PillarFinal--white__footer.svg';

export default function Navigation() {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "patrick-fore-379185-unsplash.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData
        }
      }
      site {
        siteMetadata {
          email
          phone
          hours
          facebook
          twitter
          linkedin
        }
      }
    }
  `)

  const headerBG = getImage(data.file);
  const stackedHeaderBG = [`linear-gradient(rgba(47,47,47,.7),rgba(47,47,47,.7))`,headerBG]

  return (
    <Fragment>
      <div id="about-sub" className="header--mobile__nav--sub">
        <div className="header--mobile__nav--sub--controls">
          <a href="#mobile-menu">
            &lt;
          </a>
        </div>
        <ul className="nav--sublinks">
          <li>Abogados</li>
          <li>
            <Link to="/about/darryl-j-dreyer/">Darryl J. Dreyer</Link>
          </li>
          <li>
            <Link to="/about/sarah-dreyer/">Sarah Dreyer</Link>
          </li>
          <li>
            <Link to="/about/daniel-c-jackson-iii/">Daniel C. Jackson, III</Link>
          </li>
        </ul>
      </div>
      <div id="mobile-menu" className="header--mobile__nav">
        <div className="header--mobile__nav--controls">
          <a href="#">
            X
          </a>
        </div>
        <ul>
          <li>
            <Link to="/es">Inicio</Link>
          </li>
          <li>
            <a className="toSubMenu" href="#about-sub" aria-label="mucho"></a>
            <Link to="/about">Sobre</Link>
          </li>
          <li>
            <Link to="/es/areas-de-bufete">Areas de Bufete</Link>
          </li>
          <li>
            <Link to="/news">Noticias</Link>
          </li>
        </ul>
        <div className="header--mobile__nav--social">
          <a href={`https://facebook.com/${data.site.siteMetadata.facebook}`} target="_blank" className="social-btn">
            <FaFacebookF />
          </a>
          <span className="social-btn">
            <FaInstagram />
          </span>
          <a href={`https://twitter.com/${data.site.siteMetadata.twitter}`} target="_blank" className="social-btn">
            <FaTwitter />
          </a>
          <a href={`https://linkedin.com/company/${data.site.siteMetadata.linkedin}`} target="_blank" className="social-btn">
            <FaLinkedinIn />
          </a>
          <span className="social-btn">
            <FaYoutube />
          </span>
        </div>
        <a href="tel:845-522-8910" className="primary-btn mobile-nav">
          <FaPhoneAlt />
          &nbsp;&nbsp;¡Contáctenos!
        </a>
        <Link className="secondary-btn mobile-nav" to="/">
          <FaGlobeAmericas />&nbsp;
          English
        </Link>
      </div>
      <BgImage Tag="header" image={stackedHeaderBG} className="header" preserveStackingContext>
        <div className="header--mobile">
          <div className="header--mobile__wrap">
            <div className="header--mobile__container">
              <div className="header--mobile__logo">
                <Link to="/es">
                  <Logo />
                </Link>
              </div>
              <div className="header--mobile__menu">
                <div className="header--mobile__menu--hamburger">
                  <a href="#mobile-menu" className="hamburger--button">
                    <span></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="topbar">
          <div className="topbar__background"></div>
          <div className="container">
            <div className="topbar__inner">
              <div className="topbar--left">
                <FaPhoneAlt />
                &nbsp;
                {data.site.siteMetadata.phone}
                <div className="bullet">&bull;</div>
                <FaEnvelopeOpen />
                &nbsp;
                {data.site.siteMetadata.email}
                <div className="bullet">&bull;</div>
                <FaClock />&nbsp;
                Disponible Las 24 Horas
              </div>
              <div className="topbar--right">
                <Link className="primary-btn" to="/contact-us">
                  <FaComment />&nbsp;
                  ¡Contáctenos!
                </Link>
                <Link className="secondary-btn" to="/">
                  <FaGlobeAmericas />&nbsp;
                  English
                </Link>
              </div>
              <div className="topbar--social">
                <a href={`https://facebook.com/${data.site.siteMetadata.facebook}`} target="_blank"className="social-btn">
                  <FaFacebookF />
                </a>
                <span className="social-btn">
                  <FaInstagram />
                </span>
                <a href={`https://twitter.com/${data.site.siteMetadata.twitter}`} target="_blank" className="social-btn">
                  <FaTwitter />
                </a>
                <a href={`https://linkedin.com/company/${data.site.siteMetadata.linkedin}`} target="_blank" className="social-btn">
                  <FaLinkedinIn />
                </a>
                <span className="social-btn">
                  <FaYoutube />
                </span>
              </div>
            </div>
          </div>
        </div>
        <nav className="nav">
          <div className="nav__background"></div>
          <div className="container">
            <div className="nav__inner">
              <div className="nav__logo">
                <div className="nav__logo--frame">
                  <Link className="logo" to="/es">
                    <Logo />
                  </Link>
                </div>
              </div>
              <div className="nav__right">
                <div className="mainmenu">
                  {/* embed in mainmenu--left if inserting search */}
                  <ul className="nav--links">
                    <li>
                      <Link to="/es">Inicio</Link>
                    </li>
                    <li>
                      <Link to="/about">Sobre</Link>
                    </li>
                    <li>
                      <Link to="/es/areas-de-bufete">Areas de Bufete</Link>
                    </li>
                    <li>
                      <Link to="/news">Noticias</Link>
                    </li>
                  </ul>
                  {/* mainmenu--right for search component */}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </BgImage>
    </Fragment>
  )
}