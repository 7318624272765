import React from 'react'
import { Link } from 'gatsby'
import FaFacebookF from '../../assets/faFacebookF.svg';
import FaInstagram from '../../assets/faInstagram.svg';
import FaTwitter from '../../assets/faTwitter.svg';
import FaLinkedinIn from '../../assets/faLinkedinIn.svg';
import FaYoutube from '../../assets/faYoutube.svg';
import Logo from '../../assets/PillarFinal--white__footer.svg';

export default function Footer() {
  const yyyy = new Date().getFullYear();

  return (
    <footer>
      <div className="footer--wrapper">
        <div className="container">
          <div className="column">
            <div className="wrapper">
              <div className="wrapper--inner">
                <p className="footer--disclaimer">
                  <Logo />
                  La información de este sitio web es sólo para fines de información general. Nada en este sitio debe ser tomado como consejo legal para cualquier caso o situación individual. Esta información no tiene la intención de crear, y la recepción o visualización no constituye, una relación abogado-cliente.
                </p>
                <div className="footer--social">
                  <a href="/" className="social-btn">
                    <FaFacebookF />
                  </a>
                  <a href="/" className="social-btn">
                    <FaInstagram />
                  </a>
                  <a href="/" className="social-btn">
                    <FaTwitter />
                  </a>
                  <a href="/" className="social-btn">
                    <FaLinkedinIn />
                  </a>
                  <a href="/" className="social-btn">
                    <FaYoutube />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="wrapper">
              <h3>Información del contacto</h3>
              <div className="wrapper--text">
                <p className="footer--address">
                  5419 Route 9W <br />
                  Newburgh, NY 12550
                </p>
                <p className="footer--address">
                  290 Broadway, Suite 10<br />
                  Newburgh, NY 12550
                </p>
                <p>
                  <span className="footer--phone">845.522.8910</span>
                  <br />
                  <span className="footer--email">dreyerlawoffices@aol.com</span>
                </p>
              </div>
            </div>
          </div>
          <div className="column-2">
            <div className="wrapper">
              <h3>Areas de Bufete</h3>
              <div className="footer--navigation">
                <ul>
                  <li>
                    <Link to="/">Lesiones personales</Link>
                  </li>
                  <li>
                    <Link to="/">Lesiones cerebrales y de la cabeza</Link>
                  </li>
                  <li>
                    <Link to="/">Mordidas de perro</Link>
                  </li>
                  <li>
                    <Link to="/">Accidentes automovilísticos</Link>
                  </li>
                  <li>
                    <Link to="/">Lesiones de cuello y espalda</Link>
                  </li>
                  <li>
                    <Link to="/">Accidentes por resbalones, tropiezos y caídas</Link>
                  </li>
                  <li>
                    <Link to="/">Muerte injusta</Link>
                  </li>
                  <li>
                    <Link to="/">Defensa Criminal</Link>
                  </li>
                  <li>
                    <Link to="/">Delitos de drogas</Link>
                  </li>
                  <li>
                    <Link to="/">Defensa de DWI</Link>
                  </li>
                  <li>
                    <Link to="/">Infracciones de tráfico</Link>
                  </li>
                  <li>
                    <Link to="/">Delito de cuello blanco</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="copyright">
        <div className="container">
          <div className="copyright--left">
            Derechos de autor &copy; {yyyy} Dreyer Law. Reservados todos los derechos.
            <br />
            Website Maintained by <a href="https://brandingcompanyllc.com" target="_blank">Branding Company, LLC</a>.
          </div>
          <div className="copyright--right">
            <Link to='/es'>Inicio</Link>
            <Link to='/about'>Abogados</Link>
            <Link to='/sitemap.xml'>Mapa del sitio</Link>
            <Link to='/privacy'>Política de privacidad</Link>
          </div>
        </div>
      </div>
    </footer>
  )
}